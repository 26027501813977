import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class B2bI18nService {
  private selectedLanguageSubject: BehaviorSubject<string> = new BehaviorSubject<string>("it");
  public selectedLanguage$: Observable<string> = this.selectedLanguageSubject.asObservable();

  constructor(public translate: TranslateService) {
    this.initLang();
  }

  private initLang() {
    this.translate.addLangs(["en", "it"]);
    const findLastUsedLanguage = localStorage.getItem("language");
    if (findLastUsedLanguage) {
      this.switchLang(findLastUsedLanguage);
    } else {
      this.translate.setDefaultLang("it");
      this.selectedLanguageSubject.next("it");
    }
  }

  switchLang(lang: string) {
    this.selectedLanguageSubject.next(lang);
    localStorage.setItem("language", lang);
    this.translate.use(lang);
  }
}

// export function appInitializerFactory(translate: TranslateService) {
//   return () => {
//     translate.setDefaultLang('it');
//     return translate.use('it').toPromise();
//   };
// }
